import $ from 'jquery';

function getSpecificUrlQueryValue(key) {
  const url = window.location.href;
  const params = url.split('?')[1].split('&');

  for (let i = 0; i < params.length; i += 1) {
    const param = params[i].split('=');
    if (param[0] === key) return param[1];
  }
  return [];
}

if ($('.Sec-aic-result-main').length) {
  // [debug]
  console.log('request', JSON.parse(window.sessionStorage.getItem('aciRequestSessionParams_kesennuma')));
  console.log('response', JSON.parse(window.sessionStorage.getItem('aciResponseSessionParams_kesennuma')));

  // [note]
  // 本ページはWPで取得できる内容はGetパラメータを参照しPHPで生成する
  // WPに存在しない内容は遷移前のページから引き継いだ「aciResponseSessionParams」sessionストレージを利用しjsで生成。
  // ただし、PHPとjsが用いるデータに齟齬があってはいけないので、「スポットIDだけ」両者でマッチしているか事前に確認す
  const sessionInfos = JSON.parse(
    window.sessionStorage.getItem('aciResponseSessionParams_kesennuma'),
  );
  const jcidsOfSession = sessionInfos.plan_infos.map(spot => spot.spot_id);
  const jcidsOfGetParam = getSpecificUrlQueryValue('jcids').split(',');
  const isSessionAndGetParamAreSame = (() => {
    for (let i = 0; i < jcidsOfSession.length; i += 1) {
      if (Number(jcidsOfSession[i]) !== Number(jcidsOfGetParam[i])) {
        return false;
      }
    }
    return true;
  })();

  // urlクエリとsessionStrageがマッチしたのでDOMを操作
  if (isSessionAndGetParamAreSame) {
    $('.aic-result-course-item').each((index, elm) => {
      const $this = $(elm);

      $this
        .find('.aic-result-course-item__arrival-time')
        .text(sessionInfos.plan_infos[index].arrival_time);
      $this
        .find('.aic-result-course-item__departure-time')
        .text(sessionInfos.plan_infos[index].departure_time);

      // ゴールスポット以外はフローパーツの中身をUIに表示
      if (index !== sessionInfos.plan_infos.length - 1) {
        $this
          .find('.aic-result-course-item__route-info-time')
          .text(sessionInfos.plan_infos[index].route_info.time);

        let transIconClassName = 'c-icon--by-';
        switch (sessionInfos.plan_infos[index].route_info.way) {
          case 1:
            transIconClassName += 'walk';
            break;
          case 2:
            transIconClassName += 'car';
            break;
          case 3:
            transIconClassName += 'train';
            break;
          default:
            break;
        }
        transIconClassName += '-white';
        $this
          .find('.aic-result-course-item__way-icon')
          .addClass(transIconClassName);
      }

      // Copyコースボタンのdata属性の値を生成
      $('.aic-result-course-copy-button').attr(
        'data-jc-plan-start-spot-id',
        sessionInfos.jtp_start_spot_id,
      );
      $('.aic-result-course-copy-button').attr(
        'data-jc-plan-modes',
        sessionInfos.jtp_modes.replace(/"/g, "'"), // "['car','train']"
      );
      $('.aic-result-course-copy-button').attr(
        'data-jc-plan-spot-ids',
        sessionInfos.jtp_spot_ids, // [1,2,3,4]
      );
    });
  }
}
