import $ from 'jquery';
import Aic from '../libs/Aic';

if (document.querySelector('.aic-form-set') !== null) {
  const aic = new Aic({
    crLang: document.body.dataset.lang,
    apikey: 'PqcoqdmKiq',
    apiUri: 'https://jc-cmn-api-03.machi-pla.com/api_v1/best_plans.json',
    place: 'kesennuma',
    resultPageUrl: document.querySelector('.aic-form-submit-btn').getAttribute('href'),
    chartStyle: {
      radarInnerFillColor: '#8499ca',
    },
    uiParams: {
      radarChartVals: [0.2, 0.2, 0.2, 0.2, 0.2],
    },
  });

  aic.init();

  $('.aic-form-submit-btn').on('click', () => {
    const $noItemMsg = $('.planner-no-item-msg');
    const $loadingDialog = $('.page-loading');
    const requestApiUri = aic.getApiUriWithRequestParams();

    $noItemMsg.hide();
    $loadingDialog
      .hide()
      .removeClass('d-none')
      .fadeIn(100);

    $.ajax({ url: requestApiUri })
      .done(data => {
        if (data.status.code === 0) {
          const { response } = data;
          const jcids = response.plan_infos.map(info => info.spot_id).join(',');
          // respoceのパラメータをストレージに保存しておく
          aic.setResponseParamsInSessionStorage(response);
          // requestしたパラメータも念の為ストレージに保存しておく
          aic.setRequestParamsInSessionStorage();
          window.location = `${aic.resultPageUrl}?jcids=${jcids}`;
        } else {
          $noItemMsg
            .hide()
            .removeClass('d-none')
            .fadeIn(600);
          $loadingDialog.hide();
          // eslint-disable-next-line no-console
          console.log(`[ERROR - Status Code : ${data.status.code}] ${data.status.message}`);
        }
      })
      .fail(() => {
        // eslint-disable-next-line no-console
        console.log('[ERROR] API acquisition failed');
      });
    return false;
  });

  $('.aic-form-radar-btn-set__btn').on('click', event => {
    const $this = $(event.currentTarget);
    const changeNum = 0.2;
    const cat = $this.data('cat');
    const vector = $this.data('vector');
    aic.onChangeChartValueWithBtns(changeNum, cat, vector);
    return false;
  });
} // if
