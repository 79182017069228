import $ from 'jquery';
import Swiper from 'swiper';
import objectFitImages from 'object-fit-images';
import Stickyfill from 'stickyfilljs';
import 'jquery-match-height'; // [note] When this is imported, 'matchHeight' is been jquery method.

// =========================================
// kumagai-san scripts
// =========================================

// 記事内のstickyプロパティ動作させるための高さ同期
// ---------------------------------
// [note] Change by smmt 20. 04.07
// mediumサイズ以下の1カラム構成の際はこのスクリプトがレイアウトを壊すためif分岐を定義
$(window).on('load scroll resize', function () {
    if (window.innerWidth > 768) {
        var asideHeight = $(".Sidebar__inner").innerHeight() + 200;
        $(".Main__column").css("min-height", asideHeight);
    } else {
        $(".Main__column").css("min-height", "initial");
    }
});


// Google Map Embed (for top page only)
// ---------------------------------
if (document.querySelector('.js-map-kesennuma') !== null) {
    function initMap() {
        //const target = document.getElementById('js-map-kesennuma');
        var target = document.querySelector('.js-map-kesennuma');
        var empire = {
            lat: 38.907641,
            lng: 141.570332
        };
        var map = new google.maps.Map(target, {
            center: empire,
            zoom: 6
        });
        var marker = new google.maps.Marker({
            position: empire,
            map: map,
            animation: google.maps.Animation.DROP,
            icon: {
                url: "/wp-kesennuma/en/wp-content/themes/kesennuma/app/assets/images/common/c-icon-pin.svg",
                // url: "/wp-content/themes/kesennuma/app/assets/images/common/c-icon-pin.svg",
                scaledSize: new google.maps.Size(43, 59)
            }
        });
    }
    initMap();
}

// Modaal
// ---------------------------------
if (document.querySelector('.js-modaal-menu') !== null) {
    $('.js-modaal-menu').modaal({
        hide_close: true,
        overlay_close: false,
        fullscreen: true,
        custom_class: 'modaal-menu',
        background: '#1e1e1e',
        overlay_opacity: '0.9'
    });
    $('.js-modaal-search').modaal({
        hide_close: true,
        overlay_close: false,
        fullscreen: true,
        custom_class: 'modaal-search',
        background: '#1e1e1e',
        overlay_opacity: '0.9'
    });
    $('.js-modaal-close').on('click', function () {
        $('.js-modaal-menu, .js-modaal-search').modaal('close');
    });
    $('.js-modaal-menu, .js-modaal-search, .js-modaal-close').on('click', function (ev) {
        $(ev.currentTarget).parent().toggleClass("js-modaal-active");
    });
    $(window).on('resize', function() {
        if (this.matchMedia('(min-width: 992px)').matches) {
            $('.js-modaal-menu, .js-modaal-search').modaal('close');
            $('.js-header-toggler-wrap').removeClass("js-modaal-active");
        }
    })
}

// ShareLink (plugin)
// ---------------------------------
if (document.querySelector('.share') !== null) {
    $(document).ready(function() {
        $('.share').ShareLink({
            title: forSocialShareInfo.title,
            text: forSocialShareInfo.text,
            image: forSocialShareInfo.image,
            url: forSocialShareInfo.url,
        });
    });
}

// stickyfill (plugin)
// ---------------------------------
if (document.querySelector('.position-sticky') !== null) {
    var elem = document.querySelectorAll('.position-sticky');
    Stickyfill.add(elem);
}

// swiper (plugin)
// ---------------------------------
var topAboutSwiper = new Swiper('.top-about-swiper-container', {
    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    watchOverflow: true,
    // Navigation arrows
    navigation: {
        nextEl: '.top-about-swiper-container .swiper-button-next',
        prevEl: '.top-about-swiper-container .swiper-button-prev'
    },
});


// __column
var mySwiperCol = new Swiper('.js-swiper-container.swiper.swiper__column', {
    // Optional parameters
    slidesPerView: 3,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    watchOverflow: true,
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
    },
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    // Breakpoints
    breakpoints: {
        // when window width is >= 767px
        767: {
            centeredSlides: true,
            centerInsufficientSlides: true,
            slidesPerView: 1.005,
        }
    }
});

// __column-small
var mySwiperColSmall = new Swiper('.js-swiper-container.swiper.swiper__column-small', {
    // Optional parameters
    slidesPerView: 3,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    watchOverflow: true,
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    // Breakpoints
    breakpoints: {
        // when window width is >= 767px
        767: {
            centeredSlides: true,
            centerInsufficientSlides: true,
            slidesPerView: 1.005,
        }
    }
});

// __column-single
var mySwiperColSingle = new Swiper('.js-swiper-container.swiper.swiper__column-single', {
    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
});

// __slider
window.onload = function () {
    $(".p-anim-view__scene1").addClass("is-animation");
    setTimeout(function () {
        $(".p-anim-view__scene2").addClass("is-animation");
    }, 1500);

    setTimeout(function () {
        $(".p-anim-view__scene3").addClass("is-animation");
    }, 5500);

    setTimeout(function () {
        $(".p-anim-view__scene4").addClass("is-animation");
    }, 6000);

    setTimeout(function () {
        $(".p-anim-view").fadeOut('slow');
    }, 8000);
};
$(function() {
    $('.p-anim-view__scene4').on('animationend', function() {
        setTimeout(function(){
        var mySwiperSlider = new Swiper('.js-swiper-container.swiper.swiper__slider', {
            slidesPerView: 1,
            spaceBetween: 0,
            observer: true,
            observeParents: true,
            effect: 'fade',
            speed: 1000,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        });
        },2000);
    });
});


// matchHeight (plugin)
// ---------------------------------
$(function(){
    $('.js-matchHeight').matchHeight();
});

// object-fit-images (plugin)
// ---------------------------------
objectFitImages('img.object-fit-cover');
