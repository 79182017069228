import $ from 'jquery';

class ShowAndHide {
  constructor(opts) {
    this.target = opts.target;
    this.opener = opts.opener;
    this.closer = opts.closer;
    this.hiddenClassName = opts.hiddenClassName;

    $(this.opener).on('click', () => {
      $(this.target)
        .hide()
        .removeClass(this.hiddenClassName)
        .fadeIn(200);
      return false;
    });

    $(this.closer).on('click', () => {
      $(this.target).fadeOut(400);
      return false;
    });
  }
}

export default ShowAndHide;
