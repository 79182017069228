import $ from 'jquery';
import ShowAndHide from '../utilities/ShowAndHide';

// Global Parts
// -------------------------
// Add Spot Dialog
$(() => {
  // eslint-disable-next-line no-unused-vars
  const manageAddSpotDialogShowAndHide = new ShowAndHide({
    target: '.add-spot-dialog',
    opener: '.js-add-spot-dialog-open',
    closer: '.js-add-spot-dialog-close',
    hiddenClassName: 'd-none',
  });
});

// Header language selector
$('.js-header-lang-selector').change(() => {
  window.location = $('.js-header-lang-selector option:selected').data('url');
});

// IE11 alert
(() => {
  function showIeBrowserErrorAlert() {
    const msg = {
      en:
        'Please use the recommended browser as it may not work well with your browser. Recommended browsers are Google Chrome, Microsoft Edge and Apple Safari.',
      'zh-tw':
        '請使用推薦的瀏覽器，因為它可能不適用於您的瀏覽器。 推薦的瀏覽器是Google Chrome，Microsoft Edge和Apple Safari。',
      th:
        'โปรดใช้เบราว์เซอร์ที่แนะนำเนื่องจากอาจใช้งานได้ไม่ดีกับเบราว์เซอร์ของคุณ เบราว์เซอร์ที่แนะนำ ได้แก่ Google Chrome, Microsoft Edge และ Apple Safari',
      id:
        'Harap gunakan browser yang direkomendasikan karena mungkin tidak berfungsi dengan baik dengan browser Anda. Browser yang direkomendasikan adalah Google Chrome, Microsoft Edge, dan Apple Safari.',
      ko:
        '귀하의 브라우저에서 제대로 작동하지 않을 수 있으므로 권장 브라우저를 사용하십시오. 권장되는 브라우저는 Google Chrome, Microsoft Edge 및 Apple Safari입니다.',
    };

    let userAgent = window.navigator.userAgent.toUpperCase();
    if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('TRIDENT') == -1) {
      console.log('not IE');
    } else {
      alert(msg[document.querySelector('body').dataset.lang]);
    }
  }

  // 処理はIE11に対応していない特定のページに限る
  if (
    document.querySelector('#plansMain') !== null ||
    document.querySelector('#arMapMain') !== null ||
    document.querySelector('#arMapKarakuwaMain') !== null
  ) {
    showIeBrowserErrorAlert();
  }
})();
