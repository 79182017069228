import $ from 'jquery';
import 'jquery-ui/ui/widgets/slider';

// AI Concierge用のクラス

export default class Aic {
  constructor(params = {}) {
    this.params = params;
    if (!params.chartLabels) this.params.chartLabels = {};
    if (!params.chartStyle) this.params.chartStyle = {};
    if (!params.elms) this.params.elms = {};
    if (!params.uiParams) this.params.uiParams = {};
    if (!params.uiParams.tasteSliderVals) this.params.uiParams.tasteSliderVals = {};
    this.apikey = this.params.apikey || '';
    this.apiUri = this.params.apiUri || 'https://jc-cmn-api-03.machi-pla.com/api_v1/best_plans.json';
    this.place = this.params.place || 'tokyo';
    this.defaultLang = this.params.defaultLang || 'en';
    this.crLang = this.params.crLang || 'en';
    this.resultPageUrl = this.params.resultPageUrl;
    this.chartLabels = {
      en: ['Ａ.Experience', 'Ｂ.Culture', 'Ｃ.Shopping', 'Ｄ.Art', 'Ｅ.Nature'],
      ja: ['Ａ.体験', 'Ｂ.歴史', 'Ｃ.ショッピング', 'Ｄ.アート', 'Ｅ.自然'],
      'zh-tw': ['Ａ.體驗', 'Ｂ.文化', 'Ｃ.購物', 'Ｄ.購物', 'Ｅ.自然'],
      th: ['Ａ.ประสบการณ์', 'Ｂ.วัฒนธรรม', 'Ｃ.ช้อปปิ้ง', 'Ｄ.ศิลป', 'Ｅ.ธรรมชาติ'],
    };
    this.chartStyle = {
      wrapBgColor: this.params.chartStyle.wrapBgColor || '#fff',
      radarInnerFillColor: this.params.chartStyle.radarInnerFillColor || '#f90',
      raderOutLineFillColor: this.params.chartStyle.raderOutLineFillColor || '#ccc',
      textFontFamily:
        this.params.chartStyle.textFontFamily ||
        "11px Helvetica, Arial, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', MS PGothic, sans-serif",
      textFill: this.params.chartStyle.textFill || '#000',
      textAlign: this.params.chartStyle.textAlign || 'center',
    };
    // [todo] the params should be optional 
    this.elms = {
      $startSpotIdSelect: $("select[name='data[start_spot_id]']"),
      $durationSelect: $("select[name='data[duration]']"),
      $startTimeSelect: $("select[name='data[start_time]']"),
      $daysSelect: $("select[name='data[days]']"),
      $transportationSelect: $("select[name='data[transportation]']"),
      $slider: $('.aic-form-slider__slider'),
      $radarChartCanvas: $('#rcCanvas'),
      radarChartCanvas: '#rcCanvas',
    };

    this.uiParams = {
      radarChartVals: this.params.uiParams.radarChartVals || [0.2, 0.2, 0.2, 0.2, 0.2],
      tasteSliderVals: {
        value: this.params.uiParams.tasteSliderVals.value || 0,
        min: this.params.uiParams.tasteSliderVals.min || -2,
        max: this.params.uiParams.tasteSliderVals.max || 2,
        step: 0.1,
      },
    };
    this.requestSessionStrage = `aciRequestSessionParams_${this.place}`;
    this.responseSessionStrage = `aciResponseSessionParams_${this.place}`;
  }

  init() {
    this.initTasteSlider();
    this.initRadarChart();
    this.showProfile(); // RadarChart内の項目などの初期化
  }

  // [note] 注意: jQuery UIを利用している
  initTasteSlider() {
    if ($.ui) {
      this.elms.$slider.slider({
        value: this.uiParams.tasteSliderVals.value,
        min: this.uiParams.tasteSliderVals.min,
        max: this.uiParams.tasteSliderVals.max,
        step: 0.1,
      });
    }
  }

  // レーダーチャート:  初期化
  initRadarChart() {
    const aicInstance = this;
    const vals = this.uiParams.radarChartVals;
    // レーダーチャートの設定
    let targetAxis = -1; // 軸
    const rect = this.elms.$radarChartCanvas.offset();
    const cW = this.elms.$radarChartCanvas.width(); // キャンバス横サイズ
    const cH = this.elms.$radarChartCanvas.height(); // キャンバス縦サイズ
    const rdSize = Math.min(cH / 2 - 15, cW / 2 - 25); // レーダーチャートのサイズ

    function setRadarChart(e) {
      if (e.originalEvent.changedTouches) e = e.originalEvent.changedTouches[0];
      const angle = Math.atan2(e.pageX - rect.left - cW / 2, rect.top + cH / 2 - e.pageY);
      let pos =
        (Math.sqrt((e.pageX - rect.left - cW / 2) ** 2 + (rect.top + cH / 2 - e.pageY) ** 2) *
          Math.cos(angle - (targetAxis / vals.length) * Math.PI * 2)) /
        rdSize;
      pos = Math.min(Math.max(pos, 0.2), 1);
      const pos2weight = position => ((position - 0.2) / 0.8) ** 2;

      for (let i = 0; i < vals.length; i += 1) {
        if (i !== targetAxis)
          vals[i] =
            vals[targetAxis] === 1
              ? (1 - pos2weight(pos)) / vals.length
              : (vals[i] * (1 - pos2weight(pos))) / (1 - vals[targetAxis]);
      }
      vals[targetAxis] = pos2weight(pos);
      aicInstance.showProfile();
    }

    this.elms.$radarChartCanvas.bind({
      // eslint-disable-next-line func-names
      'touchstart mousedown': function(e) {
        e.preventDefault();
        if (e.originalEvent.changedTouches) e = e.originalEvent.changedTouches[0];
        const angle =
          (Math.atan2(e.pageX - rect.left - cW / 2, rect.top + cH / 2 - e.pageY) + 2 * Math.PI) % (2 * Math.PI);
        const degree = (angle * vals.length) / (2 * Math.PI);
        if (degree % 1 <= 0.4 || degree % 1 >= 0.6) {
          targetAxis = Math.round(degree) % vals.length;
        }
      },
      // eslint-disable-next-line func-names
      'touchmove mousemove': function(e) {
        e.preventDefault();
        if (targetAxis >= 0) setRadarChart(e);
      },
      // eslint-disable-next-line func-names
      'touchend mouseup': function(e) {
        e.preventDefault();
        if (targetAxis < 0) return;
        setRadarChart(e);
        targetAxis = -1;
      },
    });
  }

  // レーダーチャート: 描画
  drawRadarChart(canvas, val, label) {
    // 与えられたcanvasにレーダーチャートを描く（val，labelは値，ラベルの列）
    const cW = canvas.width; // キャンバス横サイズ
    const cH = canvas.height; // キャンバス縦サイズ
    const rdSize = Math.min(cH / 2 - 10, cW / 2 - 20);
    const ctx = canvas.getContext('2d');
    const n = val.length;
    // 消去
    ctx.fillStyle = this.chartStyle.wrapBgColor;
    ctx.fillRect(0, 0, cW, cH);
    // レーダー内部
    ctx.fillStyle = this.chartStyle.radarInnerFillColor;
    ctx.beginPath();
    ctx.moveTo(cW / 2, cH / 2 - rdSize * val[0]);
    for (let i = 1; i < n; i += 1) {
      ctx.lineTo(
        cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2) * val[i],
        cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2) * val[i],
      );
    }

    ctx.closePath();
    ctx.fill();
    // 外周・目盛り
    ctx.fillStyle = this.chartStyle.raderOutLineFillColor;
    ctx.strokeStyle = this.chartStyle.raderOutLineFillColor;
    ctx.beginPath();
    ctx.moveTo(cW / 2, cH / 2 - rdSize);
    for (let i = 1; i < n; i += 1) {
      ctx.lineTo(cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2), cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2));
    }
    ctx.closePath();
    ctx.stroke();
    for (let i = 0; i < n; i += 1) {
      ctx.beginPath();
      ctx.moveTo(cW / 2, cH / 2);
      ctx.lineTo(cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2), cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2));
      ctx.closePath();
      ctx.stroke();
      for (let j = 1; j <= 5; j += 1) {
        ctx.beginPath();
        ctx.arc(
          cW / 2 + (rdSize * Math.sin((i / n) * Math.PI * 2) * j) / 5,
          cH / 2 - (rdSize * Math.cos((i / n) * Math.PI * 2) * j) / 5,
          2,
          0,
          Math.PI * 2,
          true,
        );
        ctx.fill();
      }
    }
    // ラベル
    ctx.font = this.chartStyle.textFontFamily;
    ctx.textAlign = this.chartStyle.textAlign;
    ctx.fillStyle = this.chartStyle.textFill;
    for (let i = 0; i < n; i += 1) {
      ctx.fillText(
        label[i],
        cW / 2 + rdSize * Math.sin((i / n) * Math.PI * 2) * 1.3,
        cH / 2 - rdSize * Math.cos((i / n) * Math.PI * 2) * 1.1 + 5,
      );
    }
  }

  // レーダーチャート: 項目内容の生成
  showProfile() {
    // 現在の嗜好を表示する
    const label = this.chartLabels[this.crLang] ? this.chartLabels[this.crLang] : this.chartLabels[this.defaultLang];
    const weight2pos = weight => Math.sqrt(weight) * 0.8 + 0.2;
    // レーダーチャートを描く
    const pos = [];
    for (let i = 0; i < this.uiParams.radarChartVals.length; i += 1) {
      pos.push(weight2pos(this.uiParams.radarChartVals[i]));
    }
    this.drawRadarChart(document.querySelector(this.elms.radarChartCanvas), pos, label);
  }

  // レーダーチャート: プロファイルの増減処理
  changeProfile(index, num) {
    // 指定された現在のプロファイル値をチェック
    let tmpChgProfile = this.uiParams.radarChartVals[index] + num;
    if (tmpChgProfile > 1) {
      tmpChgProfile = 1;
    } else if (tmpChgProfile < 0) {
      tmpChgProfile = 0;
    }
    this.uiParams.radarChartVals[index] = tmpChgProfile;
    // 変更されなかったカテゴリに割り当てるウェイトの数値合計
    const totalPoint = 1 - this.uiParams.radarChartVals[index];
    // 変更されなかったカテゴリの割合値の合計
    let totalWeight = 0;
    for (let i = 0; i < this.uiParams.radarChartVals.length; i += 1) {
      if (i !== index) {
        totalWeight += this.uiParams.radarChartVals[i];
      }
    }
    for (let i = 0; i < this.uiParams.radarChartVals.length; i += 1) {
      if (i !== index) {
        if (totalWeight > 0) {
          this.uiParams.radarChartVals[i] = totalPoint * (this.uiParams.radarChartVals[i] / totalWeight);
        } else if (totalWeight <= 0) {
          this.uiParams.radarChartVals[i] = totalPoint * (1 / (this.uiParams.radarChartVals.length - 1));
        } else {
          this.uiParams.radarChartVals[i] = 0;
        }
      }
    }
    this.showProfile();
  }

  onChangeChartValueWithBtns(changeNum, cat, vector) {
    const changeSize = vector === 'inc' ? changeNum : changeNum * -1;
    switch (cat) {
      case 'a':
        this.changeProfile(0, changeSize);
        break;
      case 'b':
        this.changeProfile(1, changeSize);
        break;
      case 'c':
        this.changeProfile(2, changeSize);
        break;
      case 'd':
        this.changeProfile(3, changeSize);
        break;
      case 'e':
        this.changeProfile(4, changeSize);
        break;
      default:
    }
  }

  getRequestParams(opts = {}) {
    const tasteArr = [];
    this.elms.$slider.each((e, el) => {
      tasteArr.push($(el).slider('value'));
    });
    let params = '';
    if (opts.includeSecretParams) {
      params += `&apikey=${this.apikey}`;
      params += `&place=${this.place}`;
    }
    // params += `&_${new Date().getTime()}`;
    params += `&start_spot_id=${this.elms.$startSpotIdSelect.val()}`;
    params += `&duration=${this.elms.$durationSelect.val()}`;
    params += `&start_time=${this.elms.$startTimeSelect.val()}`;
    params += `&days=${this.elms.$daysSelect.val()}`;
    params += `&transportation=${this.elms.$transportationSelect.val()}`;
    params += `&taste=${tasteArr.toString()}`;
    params += `&pref=${this.uiParams.radarChartVals.toString()}`;
    return params;
  }

  setRequestParamsInSessionStorage() {
    if ('sessionStorage' in window && window.sessionStorage !== null) {
      const tasteArr = [];
      this.elms.$slider.each((e, el) => {
        tasteArr.push($(el).slider('value'));
      });
      const params = {
        start_spot_id: this.elms.$startSpotIdSelect.val(),
        duration: this.elms.$durationSelect.val(),
        start_time: this.elms.$startTimeSelect.val(),
        days: this.elms.$transportationSelect.val(),
        transportation: this.elms.$transportationSelect.val(),
        taste: tasteArr.toString(),
        pref: this.uiParams.radarChartVals.toString(),
      };
      window.sessionStorage.setItem(this.requestSessionStrage, JSON.stringify(params));
    } else {
      // eslint-disable-next-line no-console
      console.log('[Error] Session storage cannot be used in this environment.');
    }
  }

  setResponseParamsInSessionStorage(val) {
    if ('sessionStorage' in window && window.sessionStorage !== null) {
      window.sessionStorage.setItem(this.responseSessionStrage, JSON.stringify(val));
    }
  }

  // APIにリクエストするURI
  getApiUriWithRequestParams() {
    const params = this.getRequestParams({ includeSecretParams: true });
    const urlWithParams = `${this.apiUri}?${params}`;
    return urlWithParams;
  }
}
