import $ from 'jquery';
import Aic from '../libs/Aic';

// for top page why kesennuma slider in modal.
if (document.querySelector('.js-top-about-slideshow') !== null) {
  $(() => {
    let index = 0;
    const $pic = $('.top-about-slideshow__pic');
    const $prevBtn = $('.js-top-about-pics-nav-prev');
    const $nextBtn = $('.js-top-about-pics-nav-next');
    const picLength = $('.top-about-slideshow__pic').length;

    function updatePicAndBtn() {
      $pic.hide();
      $pic
        .eq(index)
        .hide()
        .removeClass('d-none')
        .fadeIn(300);
      $prevBtn.removeClass('disabled');
      $nextBtn.removeClass('disabled');
      if (index === 0) $prevBtn.addClass('disabled');
      if (index === picLength - 1) $nextBtn.addClass('disabled');
    }

    $prevBtn.on('click', () => {
      if (index > 0) {
        index--;
        updatePicAndBtn();
      }
    });

    $nextBtn.on('click', () => {
      if (index < picLength - 1) {
        index++;
        updatePicAndBtn();
      }
    });
  });
}

if (document.querySelector('.js-top-aic-submit-btn') !== null) {
  const topAic = new Aic({
    apikey: 'PqcoqdmKiq',
    apiUri: 'https://jc-cmn-api-03.machi-pla.com/api_v1/best_plans.json',
    place: 'kesennuma',
    resultPageUrl: $('.js-top-aic-submit-btn').data('url'),
  });

  $('.js-top-aic-submit-btn').on('click', () => {
    const apiKey = 'PqcoqdmKiq';
    const place = 'kesennuma';
    const startPoint = $('.aic-top-from-selector').val();
    const duration = $('.aic-top-duration-selector').val();
    const startTime = 9;
    const day = 0;
    const transportation = 1;
    const taste = $('.aic-top-feeling-selector option:selected').data('taste');
    const pref = $('.aic-top-feeling-selector option:selected').data('pref');
    const $noItemMsg = $('.aic-top-no-item-msg');
    const $loadingDialog = $('.page-loading');
    const apiUrl = 'https://jc-cmn-api-03.machi-pla.com/api_v1/best_plans.json';
    let queries = '';
    queries += `?apikey=${apiKey}`;
    queries += `&place=${place}`;
    queries += `&start_spot_id=${startPoint}`;
    queries += `&duration=${duration}`;
    queries += `&start_time=${startTime}`;
    queries += `&day=${day}`;
    queries += `&transportation=${transportation}`;
    queries += `&taste=${taste}`;
    queries += `&pref=${pref}`;
    const requestApiUri = `${apiUrl}${queries}`;
    // console.log(requestApiUri);

    $noItemMsg.hide();
    $loadingDialog
      .hide()
      .removeClass('d-none')
      .fadeIn(100);

    $.ajax({ url: requestApiUri })
      .done(data => {
        if (data.status.code === 0) {
          const { response } = data;
          const jcids = response.plan_infos.map(info => info.spot_id).join(',');
          // respoceのパラメータをストレージに保存しておく
          topAic.setResponseParamsInSessionStorage(response);
          // requestしたパラメータも念の為ストレージに保存しておく
          topAic.setRequestParamsInSessionStorage();
          window.location = `${topAic.resultPageUrl}?jcids=${jcids}`;
        } else {
          $noItemMsg
            .hide()
            .removeClass('d-none')
            .fadeIn(600);
          $loadingDialog.hide();
          // eslint-disable-next-line no-console
          console.log(
            `[ERROR - Status Code : ${data.status.code}] ${data.status.message}`,
          );
        }
      })
      .fail(() => {
        // eslint-disable-next-line no-console
        console.log('[ERROR] API acquisition failed');
      });
    return false;
  });
}
