import $ from 'jquery';

function scrollToTrekkingTabLocation(e) {
  const $this = $(e.currentTarget);
  // スクロールのスピード
  const speed = 500;
  // リンク元を取得
  const href = $this.attr('data-url');
  // リンク先を取得
  const target = $(href == '#' || href == '' ? 'html' : href);
  // リンク先までの距離を取得
  const position = target.offset().top;
  // スムーススクロール
  $('html, body').animate({ scrollTop: position }, speed, 'swing');
}

function changeTrekkingTab() {
  if ($('.js-trekking-course-tab-2').prop('checked')) {
      $('.js-trekking-course-tab-1').prop('checked', true);
  } else {
      $('.js-trekking-course-tab-2').prop('checked', true);
  }
}

if (document.querySelector('.trekking-course-tab__bottom-label') != null) {
  $('.trekking-course-tab__bottom-label').on('click', e => {
    scrollToTrekkingTabLocation(e);
    changeTrekkingTab();
    return false;
  });
}
