import 'bootstrap';

// Vender
// [note] I added these jquery import line.
import './vender/SocialShare_with_jquery_import';
import './vender/modaal.min_with_jquery_import';

// Pages
import './pages/base'; // smmt base scripts
import './pages/main'; // kumagai-san base scripts
import './pages/top';
import './pages/concierge';
import './pages/concierge-result';
import './pages/extendsBootstrap.js';
import './pages/trekking';
