import $ from 'jquery';
import 'bootstrap';

// ref. [How to Make the Bootstrap Navbar Dropdown Work on Hover](https://webdesign.tutsplus.com/tutorials/how-to-make-the-bootstrap-navbar-dropdown-work-on-hover--cms-33840)

const headerDropdown = {
  $drower: $('.collapse-toggle-hover'),
  $drowerTarget: $('.Header .dropdown-menu'),
};

headerDropdown.$drower.on('mouseenter', function() {
  if (window.matchMedia('(min-width: 992px)').matches) {
    headerDropdown.$drowerTarget.hide();
    $(this)
      .find('.dropdown-menu')
      .fadeIn(300);
  }
});

headerDropdown.$drower.on('mouseleave', function() {
  if (window.matchMedia('(min-width: 992px)').matches) {
    headerDropdown.$drowerTarget.hide();
  }
});

// $(window).on('load resize', function() {
//   if (this.matchMedia('(min-width: 992px)').matches) {
//     headerDropdown.$drower.on('mouseenter', function() {
//       headerDropdown.$drowerTarget.hide();
//       $(this)
//         .find('.dropdown-menu')
//         .fadeIn(300);
//     });
//     headerDropdown.$drower.on('mouseleave', function() {
//       headerDropdown.$drowerTarget.hide();
//     });
//   }
// });

$(window).on('load resize', function() {
  if (this.matchMedia('(min-width: 768px)').matches) {
    $('#collapseAreaN').collapse('show');
  } else {
    $('#collapseAreaN').collapse('hide');
  }
});

$('.trekking-course-content__viewmore').on('click', function() {
  $('#collapseAreaN').collapse('show');
});
